import i18n from '@/i18n'

export enum ProjectTypes {
  branding = 'branding',
  logo = 'logo',
  tShirt = 't-shirt',
  menu = 'menu',
  businessCard = 'business-card',
  packaging = 'packaging',
  website = 'website',
  calendar = 'calendar',
  banner = 'banner',
  facebookCover = 'facebook-cover',
  bookCover = 'book-cover',
  flier = 'flier',
  carSticker = 'car-sticker',
  productLabel = 'product-label',
  brochure = 'brochure',
  illustration = 'illustration',
  other = 'other',
}

export const projectTypesConfig: any = {
  types: {
    // Title needed for multilanguage in the future
    [ProjectTypes.branding]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.branding}.svg`),
      title: i18n.t('Branding'),
    },
    [ProjectTypes.logo]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.logo}.svg`),
      title: i18n.t('Logo design'),
    },
    [ProjectTypes.tShirt]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.tShirt}.svg`),
      title: i18n.t('T-Shirt'),
    },
    [ProjectTypes.menu]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.menu}.svg`),
      title: i18n.t('Menu'),
    },
    [ProjectTypes.businessCard]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.businessCard}.svg`),
      title: i18n.t('Business Card'),
    },
    [ProjectTypes.packaging]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.packaging}.svg`),
      title: i18n.t('Packaging'),
    },
    [ProjectTypes.website]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.website}.svg`),
      title: i18n.t('Website'),
    },
    [ProjectTypes.calendar]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.calendar}.svg`),
      title: i18n.t('Calendar'),
    },
    [ProjectTypes.banner]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.banner}.svg`),
      title: i18n.t('Banner'),
    },
    [ProjectTypes.facebookCover]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.facebookCover}.svg`),
      title: i18n.t('Facebook Cover'),
    },
    [ProjectTypes.bookCover]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.bookCover}.svg`),
      title: i18n.t('Book Cover'),
    },
    [ProjectTypes.flier]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.flier}.svg`),
      title: i18n.t('Flier'),
    },
    [ProjectTypes.carSticker]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.carSticker}.svg`),
      title: i18n.t('Car Sticker'),
    },
    [ProjectTypes.productLabel]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.branding}.svg`),
      title: i18n.t('Product Label'),
    },
    [ProjectTypes.brochure]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.brochure}.svg`),
      title: i18n.t('Brochure'),
    },
    [ProjectTypes.illustration]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.illustration}.svg`),
      title: i18n.t('Illustration'),
    },
    [ProjectTypes.other]: {
      icon: () => import(`@/assets/icons/project-types/inline.${ProjectTypes.other}.svg`),
      title: i18n.t('Other'),
    },
  },
}

export default projectTypesConfig
