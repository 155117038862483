import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import http from '@/shared/http'
import _ from 'lodash'
import { DesignerFilter } from '@/shared/configs/project/designer-filter.config'
import ResponseInterface from '../interfaces/response.interface'
import User from '../interfaces/models/user/user'
import { Designer } from '../interfaces/designer'
import UserDetailsMedia from '../interfaces/models/user/user-details-media'

@Component
export class DesignerFilterOrder extends Vue {
  @Prop() selectedFilter!: string
  @Prop({ default: '' }) selectedCategory!: string
  @Prop() endpoint!: string
  @Prop() search!: string
  @Prop() marked!: boolean
  @Prop() value!: string[]

  designers: Designer = {
    designers: [],
    loaded: false,
    meta: null,
  }

  get order(): string {
    if (typeof this.selectedFilter !== 'undefined' && this.selectedFilter === DesignerFilter.projectsCompleted) {
      return 'desc'
    }

    return 'asc'
  }

  @Watch('selectedFilter')
  changeFilter() {
    this.loadDesigners()
  }
  @Watch('selectedCategory')
  changeCategory() {
    this.loadDesigners()
  }
  @Watch('search')
  searchForDesigner() {
    this.lookUpDebounced()
  }

  created() {
    const urlParams = new URLSearchParams(window.location.search)

    let page = 1

    if (urlParams.has('page')) {
      page = Number(urlParams.get('page') || 1)
    }
    this.loadDesigners(page)
  }

  lookUpDebounced() {
    this.lookUpDebounced = _.debounce(this.loadDesigners, 300)
  }

  loadDesigners(page = 1): void {
    this.designers.loaded = false

    let requestUrl = `/users/${this.endpoint}?q=${this.search}&per_page=8&page=${page}${this.markedQuery}&sort_by=${this.selectedFilter}&order=${this.order}`

    if (this.selectedCategory) {
      requestUrl += `&filter-json-portfolioItems-category=${this.selectedCategory}`
    }
    http.get(requestUrl).then((response: ResponseInterface<User[]>) => {
      this.designers = {
        designers: response.data.data,
        loaded: true,
        meta: response.data.meta,
      }
    })
  }

  onPageClick(page: number, urlPagination = false): void {
    if (page === this.designers.meta?.current_page) return

    this.loadDesigners(page)
    document.getElementById('talentGallery')?.scrollIntoView({ behavior: 'smooth', block: 'center' })

    if (urlPagination) {
      const searchURL = new URL(String(window.location))
      searchURL.searchParams.set('page', String(page))

      window.history.pushState({}, '', String(searchURL))
    }
  }

  getFirstLetters(name: string) {
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
  }

  getProfilePhoto(data: UserDetailsMedia[]): string | null {
    if (data.length <= 0) return null

    const picture = _.find(data, { tag: 'user-details-profile-picture' })

    if (picture) return picture.url

    return null
  }

  get markedQuery() {
    return this.marked ? `&filter-in-user_uuid=${this.value}` : ''
  }
}
